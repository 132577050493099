import React from "react";
import { FaSquareXTwitter } from "react-icons/fa6";

import testimonial1 from "../assets/customers/cla.webp";
import testimonial2 from "../assets/customers/cla2.webp";
import testimonial3 from "../assets/customers/cla3.webp";
import testimonial4 from "../assets/customers/cla4.webp";
import gallery1 from "../assets/gallery/BolehLandXYZ (1).webp";
import gallery2 from "../assets/gallery/BolehLandXYZ (2).webp";
import gallery3 from "../assets/gallery/BolehLandXYZ (3).webp";
import gallery4 from "../assets/gallery/BolehLandXYZ (4).webp";
import gallery5 from "../assets/gallery/BolehLandXYZ (5).webp";
import gallery6 from "../assets/gallery/BolehLandXYZ (6).webp";
import gallery7 from "../assets/gallery/BolehLandXYZ (7).webp";
import gallery8 from "../assets/gallery/BolehLandXYZ (8).webp";
import gallery9 from "../assets/gallery/BolehLandXYZ (9).webp";
import gallery10 from "../assets/gallery/BolehLandXYZ (10).webp";
// import gallery9 from "../assets/gallery/gallery-9.jpg";
// import gallery10 from "../assets/gallery/gallery-10.jpg";
// import gallery11 from "../assets/gallery/gallery-11.jpg";
// import gallery12 from "../assets/gallery/gallery-12.jpg";
import "./Team.css";

const Team = () => {
  return (
    <section className="section-testimonials" id="Team">
      <div className="testimonials-container">
        <span className="subheading">The Team</span>
        <h2 className="heading-secondary">
        Who Are We
        </h2>

        <div className="testimonials">
          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Team LA Chieng"
              src={testimonial1}
            />
            <blockquote className="testimonial-text">
            As the visionary Founder of BolehLandXYZ, I'm driven by innovation and opportunity. My mission? To revolutionize the Digital Space, from humble beginnings to bold aspirations, leading the charge towards success.
            </blockquote>
            <p className="testimonial-name">&mdash; LA Chieng | The Founder</p>
            <br/>
            <a
                className="footer-link"
                href="https://twitter.com/tech_lala"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FaSquareXTwitter className="text-white larger-icon cursor-pointer" />
              </a>
          </figure>

          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Team LA Chieng"
              src={testimonial2}
            />
            <blockquote className="testimonial-text">
            As a passionate Developer, I'm on a perpetual quest for digital excellence. Whether crafting elegant solutions or delving into software architecture, I turn lines of code into works of art.
            </blockquote>
            <p className="testimonial-name">&mdash; LA Chieng | The Developer</p>
          </figure>

          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Team LA Chieng"
              src={testimonial3}
            />
            <blockquote className="testimonial-text">
            The Master Builder of BolehLandXYZ. Armed with a blueprint and a hammer, I turn dreams into reality. From sleek interfaces to optimized systems, I architect our digital empire, building bridges to the future with every keystroke.
            </blockquote>
            <p className="testimonial-name">&mdash; LA Chieng | The Builder</p>
          </figure>

          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Team LA Chieng"
              src={testimonial4}
            />
            <blockquote className="testimonial-text">
            A passionate Technology Enthusiast pushing boundaries. From AI to blockchain, I explore the cutting edge, geeking out over gadgets. Join me on a journey of discovery in innovation and possibility. Also, I'm a crypto and blockchain enthusiast!
            </blockquote>
            <p className="testimonial-name">&mdash; LA Chieng | Technology Enthusiast</p>
          </figure>
        </div>
      </div>

      <div className="gallery">
        <figure className="gallery-item">
          <img
            src={gallery1}
            alt="bolehlandXYZgallery1"
          />
          {/* <figcaption>Caption</figcaption> */}
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery2}
            alt="bolehlandXYZgallery2"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery3}
            alt="bolehlandXYZgallery3"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery4}
            alt="bolehlandXYZgallery4"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery5}
            alt="bolehlandXYZgallery5"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery6}
            alt="bolehlandXYZgallery6"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery7}
            alt="bolehlandXYZgallery7"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery8}
            alt="bolehlandXYZgallery8"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery9}
            alt="bolehlandXYZgallery9"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery10}
            alt="bolehlandXYZgallery10"
          />
        </figure>
        {/* <figure className="gallery-item">
          <img
            src={gallery11}
            alt="Beautifully
      arranged food"
          />
        </figure>
        <figure className="gallery-item">
          <img
            src={gallery12}
            alt="Beautifully
      arranged food"
          />
        </figure> */}
      </div>
    </section>
  );
};

export default Team;

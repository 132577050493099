import React from "react";

import appScreen1 from "../assets/app/app-screen-1.webp";
import appScreen2 from "../assets/app/app-screen-2.webp";
import appScreen3 from "../assets/app/app-screen-3.webp";
import "./Insights.css";

const Insights = () => {
  return (
    <section className="section-how" id="Insights">
      <div className="container">
        <span className="subheading">Insights:Stop Website Wasteland, Cultivate Conversions: Masterful Landing Pages for Growth</span>
        <h2 className="step-description">
        Is your website a lead-less wasteland? ️ BolehLandXYZ's targeted landing pages are your oasis! Attract & convert visitors with laser-focused messages & effortless management. Grow your business, not your frustration.
        </h2>
      </div>

      <div className="container grid grid--2-cols grid--center-v">
        {/* STEP 01 */}
        <div className="step-text-box">
          <p className="step-number">01</p>
          <h3 className="heading-tertiary">
          Frustrated by website visitors disappearing like mirages?
          </h3>
          <p className="step-description">
            
          Watch leads slip through your fingers like desert sand? Fear not! BolehLandXYZ's targeted landing pages transform your website into a lead-capturing oasis.
          </p>
        </div>
        <div className="step-img-box">
          <img
            src={appScreen1}
            className="step-img"
            alt="iPhone app
      preferences selection screen"
          />
        </div>

        {/* STEP 02 */}
        <div className="step-img-box">
          <img
            src={appScreen2}
            className="step-img"
            alt="iPhone app
      meal approving plan screen"
          />
        </div>
        <div className="step-text-box">
          <p className="step-number">02</p>
          <h3 className="heading-tertiary">From Lost to Loyal: Guided Conversions with Landing Pages</h3>
          <p className="step-description">
          Imagine visitors magnetically drawn to clear messaging and calls to action, effortlessly guided towards conversion. Think of it as a personal tour guide for each visitor, ensuring they find what they need and take the desired action.


          </p>
        </div>

        {/* STEP 03 */}
        <div className="step-text-box">
          <p className="step-number">03</p>
          <h3 className="heading-tertiary">From Traffic to Triumph: Targeted Conversions Made Easy
</h3>
          <p className="step-description">
          Ready to turn website traffic into a thriving business ecosystem?
          
          BolehLandXYZ empowers you with:
          </p>
          <ul>
    <li className="step-description"><b>Laser-focused conversions: </b> No more wandering visitors! Get specific actions with targeted messaging.</li>
    <li className="step-description"><b>Soaring ROI:</b> Ditch expensive ads, boost results with cost-effective solutions.</li>
    
    <li className="step-description"><b>Effortless management:</b> Track, update, optimize with ease. More time for growth!</li>
  </ul>
        </div>
        <div className="step-img-box">
          <img
            src={appScreen3}
            className="step-img"
            alt="iPhone app
      delivery screen"
          />
        </div>
      </div>
    </section>
  );
};

export default Insights;

import React from "react";
import { animateScroll } from "react-scroll";

import logo from "../assets/logo.png";
// import bottom from "../assets/bottom.webp";
import "./Footer.css";
import { FaWhatsapp } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";

import {
  IoBriefcaseOutline,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoWhatsapp,IoBusinessOutline,IoBuild,
} from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";


const Footer = () => {
  return (
    <footer className="footer">
      <div className="container grid grid--footer" >
        <div className="logo-col" >
          <div class="footer-logo" >
            <img
              className="logo"
              alt="Ultrafood logo"
              src={logo}
              onClick={() => animateScroll.scrollToTop()}
              title="Go to Top"
            />
          </div>

          <ul className="social-links">
            <li>
              <a
                className="footer-link"
                href="https://wa.me/60122260821/?text=I'm%20inquiring%20about%20the%20LandingPage"
                target="_blank"
                rel="noreferrer noopener"
                title="Whatsapp me" // Add title attribute for the hint
              >
                <FaWhatsapp className="text-white larger-icon cursor-pointer" />
 
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://twitter.com/BolehLandXYZ"
                target="_blank"
                rel="noreferrer noopener"
                title="Follow me on X" // Add title attribute for the hint
              >
                <FaSquareXTwitter className="text-white larger-icon cursor-pointer" />
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="mailto:bolehlandxyz@gmail.com?subject=LandingPage"
                target="_blank"
                rel="noreferrer noopener"
                title="Email me" // Add title attribute for the hint
              >
               <AiOutlineMail className="text-white larger-icon cursor-pointer" />
              </a>
            </li>
          </ul>

          <p className="copyright">
            BolehLandXYZ &copy; {new Date().getFullYear()}
          </p>
        </div>
        <div className="address-col"  style={{ marginright: "auto" }}>
          <p className="footer-heading">Contact us</p>
          <address className="contacts">
            <p className="address">
              Subang Jaya <br /> Selangor, Malaysia
            </p>
            
            <p>
              {/* <a className="footer-link" href="tel:214-777-4141">
                214-777-4141
              </a> */}
              <br />
              <a className="footer-link" href="mailto:bolehlandxyz@gmail.com?subject=LandingPage"  title="Email me" >
              bolehlandxyz@gmail.com
              </a>
            </p>
          </address>
        </div>
        
        {/* <img src={bottom} alt="Bottom Image" style={{ width: "100%", position: "absolute", bottom: 0, zIndex: -1 }} /> */}
        {/* <nav className="nav-col">
          <p className="footer-heading">Account</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="/ ">
                Create account
              </a>
            </li>
            <li>
              <a className="footer-link" href="/">
                Sign in
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://apps.apple.com/us/app/ultrafood/id341232717"
                target="_blank"
                rel="noreferrer noopener"
              >
                iOS app
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://play.google.com/store/apps/details?id=com.ultrafood.android"
                target="_blank"
                rel="noreferrer noopener"
              >
                Android app
              </a>
            </li>
          </ul>
        </nav> */}

        {/* <nav className="nav-col">
          <p className="footer-heading">Company</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="/">
                About Ultrafood
              </a>
            </li>
            <li>
              <a className="footer-link" href="/">
                For Business
              </a>
            </li>
            <li>
              <a className="footer-link" href="/">
                Cooking partners
              </a>
            </li>
          </ul>
        </nav> */}

        {/* <nav className="nav-col">
          <p className="footer-heading">Resources</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="/">
                Recipe directory
              </a>
            </li>
            <li>
              <a className="footer-link" href="/">
                Help center
              </a>
            </li>
            <li>
              <a className="footer-link" href="/">
                Privacy & terms
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { Link } from "react-scroll";

// import customer1 from "../assets/customers/customer-1.jpg";
// import customer2 from "../assets/customers/customer-2.jpg";
// import customer3 from "../assets/customers/customer-3.jpg";
// import customer4 from "../assets/customers/customer-4.jpg";
// import customer5 from "../assets/customers/customer-5.jpg";
// import customer6 from "../assets/customers/customer-6.jpg";
// import heroWebp from "../assets/hero/hero-min.webp";
import heroPng from "../assets/hero/hero-min2.webp";
// import heroPng2 from "../assets/hero/CNYDragon.png";
import "./Hero.css";

const Hero = () => {
  useEffect(() => {
    // Sticky navigation
    const sectionHeroEl = document.querySelector(".section-hero");

    const obs = new IntersectionObserver(
      (entries) => {
        const ent = entries[0];

        if (ent.isIntersecting === false) {
          document.body.classList.add("sticky");
        }

        if (ent.isIntersecting === true) {
          document.body.classList.remove("sticky");
        }
      },
      {
        // In the viewport
        root: null,
        threshold: 0,
        rootMargin: "-80px",
      }
    );
    obs.observe(sectionHeroEl);
  });

  

  return (
    <section className="section-hero">
      <div className="hero">
        <div className="hero-text-box">
          <h1 className="heading-primary">
          Launch Sales with Laser-Focused Landing Pages | BolehLandXYZ
          </h1>
          <p class="hero-description">
  <h2>No More Website Frustration! Build a Stunning Site & Attract More Customers.</h2>
  <p>Are abandoned carts and website tumbleweeds haunting your e-commerce dreams? Don't settle for a lonely online presence! Attract and convert high-quality leads with BolehLandXYZ's custom-built landing pages. We understand the unique challenges e-commerce startups face, and we're here to help you overcome them.</p>
 
  {/* <ul>
    <li><b>SME: </b> Boost sales and brand awareness with laser-focused campaigns.</li>
    <li><b>Micro-business:</b> Launch quickly and affordably with a professional online presence.</li>
    
    <li><b>Online community:</b> Foster engagement and attract new members with a dynamic platform.</li>
    <li><b>Personal brand:</b> Amplify your influence and convert followers into fans.</li>
  </ul> */}
  <div class="cta-container">
    <h3>Scroll down to discover how we can help or contact us today for a free consultation!</h3>
    
  </div>
</p>
          <Link
            className="btn btn--full margin-right-sm"
            to="ContactUs"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Contact Now
          </Link>
          <Link
            className="btn btn--outline"
            to="Insights"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            Dive into &darr;
          </Link>
          {/* <div className="delivered-meals">
            <div className="delivered-imgs">
              <img src={customer1} alt="Customer" />
              <img src={customer2} alt="Customer" />
              <img src={customer3} alt="Customer" />
              <img src={customer4} alt="Customer" />
              <img src={customer5} alt="Customer " />
              <img src={customer6} alt="Customer" />
            </div>
            <p className="delivered-text">
              <span>100,000+</span> meals delivered last year!
            </p>
          </div> */}
        </div>
        <div className="hero-img-box">
          <picture>

          
            {/* <source srcSet={heroWebp} type="image/webp" /> */}
            <source srcSet={heroPng} type="image/png" />

            <img
              src={heroPng}
              className="hero-img"
              alt="BolehLandXYZ"
            />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default Hero;

import React from "react";
import Link from "react-scroll/modules/components/Link";

import meal1 from "../assets/meals/meal-1.webp";
import meal2 from "../assets/meals/meal-2.webp";
import meal3 from "../assets/meals/meal-3.webp";
import meal4 from "../assets/meals/meal-4.webp";
import "./Clients.css";

const Clients = () => {
  return (
    <section className="section-meals" id="Clients">
      <div className="container center-text">
        <span className="subheading">Clients:Who we cover</span>
        <h2 className="heading-secondary">
        Empowering Businesses of All Sizes & Individuals
        </h2>
      </div>

      <div className="container grid grid--2-cols margin-bottom-md">
        

        <div className="meal">
          <img src={meal2} className="meal-img" alt="customer: SME" />
          <div className="meal-content">
          <p className="meal-title">SME, Micro Businesses & Individual Experts</p>
            <div className="meal-tags">
              <span className="tag tag--vegan">Attract targeted leads</span>
              
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Boost conversions</span>
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Showcase your expertise</span>
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Build trust & credibility</span>
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Cost-effective marketing</span>
            </div>  
           

            {/* <p className="meal-title">Avocado Salad</p>
            <ul className="meal-attributes">
              <li className="meal-attribute">
                <ion-icon class="meal-icon" name="flame-outline"></ion-icon>
                <span>
                  <strong>400</strong> calories
                </span>
              </li>
              <li class="meal-attribute">
                <ion-icon
                  class="meal-icon"
                  name="restaurant-outline"
                ></ion-icon>
                <span>
                  NutriScore &reg; <strong>92</strong>
                </span>
              </li>
              <li className="meal-attribute">
                <ion-icon class="meal-icon" name="star-outline"></ion-icon>
                <span>
                  <strong>4.8</strong> rating (441)
                </span>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="meal">
          <img src={meal3} className="meal-img" alt="blogger/KOL" />
          <div className="meal-content">
          <p className="meal-title">Blogger, KOL, Community builder</p>
            <div className="meal-tags">
              <span className="tag tag--vegan">Grow your audience</span>
              
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Promote events & offerings</span>
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Collect valuable data</span>
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Monetize your influence</span>
            </div>
            <div className="meal-tags">
              <span className="tag tag--vegan">Establish your brand</span>
            </div>  
            
            <div className="meal-tags">
              <span className="tag tag--vegan">Build community</span>
            </div> 
            <div className="meal-tags">
              <span className="tag tag--vegan">Amplify your message</span>
            </div> 

            
           

            {/* <p className="meal-title">Avocado Salad</p>
            <ul className="meal-attributes">
              <li className="meal-attribute">
                <ion-icon class="meal-icon" name="flame-outline"></ion-icon>
                <span>
                  <strong>400</strong> calories
                </span>
              </li>
              <li class="meal-attribute">
                <ion-icon
                  class="meal-icon"
                  name="restaurant-outline"
                ></ion-icon>
                <span>
                  NutriScore &reg; <strong>92</strong>
                </span>
              </li>
              <li className="meal-attribute">
                <ion-icon class="meal-icon" name="star-outline"></ion-icon>
                <span>
                  <strong>4.8</strong> rating (441)
                </span>
              </li>
            </ul> */}
          </div>
        </div>
        
        {/* <div className="diets">
          <h3 className="heading-tertiary">Works with any diet:</h3>
          <ul className="list">
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Vegetarian</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Vegan</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Pescatarian</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Gluten-free</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Lactose-free</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Keto</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Paleo</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Low FODMAP</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Kid-friendly</span>
            </li>
          </ul>
        </div> */}
      </div>

      <div className="container all-recipes">
        <Link
          className="link"
          to="cta"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Contact Now &rarr;
        </Link>
      </div>
    </section>
  );
};

export default Clients;

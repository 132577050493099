import React, { useState } from "react";
import "./FAQSection.css"; // Import the CSS file
import Activities from "../assets/Activities.png";

const FAQSection = () => {
  // Define FAQ data
  const faqs = [
    {
      question: "How do I get started with creating a landing page?",
      answer:
        "Hit the Submit Button and  will contact u and provide guidance on the initial steps, including defining the purpose of the landing page, identifying goals.",
    },
    {
      question: "What information do you need from me to create a landing page?",
      answer:
        "Details on the information and materials needed from the customer, such as branding assets (logo, colors, fonts), content (copywriting, images, videos), Your product, service, or offer, About Us/Company information, list of FAQ, your Contact/Support, your social media url.",
    },
    {
      question: "Can you help me define the purpose and goals of my landing page?",
      answer:
        "Assistance in clarifying the objectives of the landing page, identifying target audience demographics, and determining key performance indicators (KPIs) for measuring success.",
    },
    {
      question: "Do I need to have technical skills to use your service?",
      answer:
        "You don't have to.",
    },
    {
      question: "How long does it take to create a landing page with your service?",
      answer:
        "I will deliver the 1st draft for your review within 7 days upon all the required info are provided from you. Information on typical turnaround times, factors that may affect project timelines (e.g., complexity of design, availability of content), and any expedited options available.",
    },
    {
      question: "Can I preview and approve the design before the landing page goes live?",
      answer:
        "Confirmation of the review and approval process, including opportunities for feedback and revisions before finalizing the design and launching the landing page.",
    },
    {
      question: "How do I track the performance of my landing page after it's live?",
      answer:
        "Explanation of analytics and tracking capabilities provided, including insights into visitor behavior, conversion tracking, and ongoing optimization recommendations.",
    },

    {
      question: "How do I pay for your services?",
      answer:
        "We accept payment via bank transfer. Upon agreement to proceed with our services, we require a 10% booking fee of the total price plan to secure your spot in our schedule and initiate work.",
    },
    {
      question: "What happens if I decide to terminate the project during the first revision?",
      answer:
        "If you request to terminate the project during the first revision stage, we will refund 5% of the booking fee. The remaining 5% will cover administrative costs and time invested in the initial phase of the project.",
    },

    {
      question: "What is the payment schedule for the project?",
      answer:
        "After the initial revision stage, if you decide to proceed with the project, we will require an additional payment of 60% of the total price plan before the final revision and go-live stage. The remaining 30% of the price plan shall be paid on the go-live date, upon completion of the project and your satisfaction with the final deliverables. Please note that no refunds will be provided after the second and final payment.",
      image:{Activities}
    },

    {
      question: "I am a user from overseas. How can I pay you?",
      answer:
        "We understand that you may be located outside our local area. To accommodate overseas users, we accept payment via Stripe, a secure and widely-used online payment platform. You can easily make payments using your credit/debit card through Stripe. We handle all transactions securely through Stripe's payment processing system.",
    },
    {
      question: "Can I meet you?",
      answer:
        "Yes, we can arrange for in-person meetings in the Subang Jaya area only. If you're located outside of Subang Jaya or prefer a remote meeting, we can conduct an online meeting. Typically, we will need about 30 minutes for our initial discussion.",
    },

    {
      question: "I'm not sure if I need a landing page or if I need another solution for my SME. Can you help?",
      answer:
        "Yes, absolutely. If you're unsure about whether a landing page is the right solution for your SME, let's have a discussion to explore your needs further. I can provide insights and help you understand the benefits of a landing page, as well as alternative solutions tailored to your specific requirements. If there are other services or solutions I can offer outside of the landing page price plan, we can discuss those options further, along with any associated service charges. Let's connect and find the best solution for your SME together.",
    },

    {
      question: "Why is your domain name bolehland.xyz?",
      answer:
        "The choice of our domain name, bolehland.xyz, reflects a blend of cultural identity and internet versatility. (Bolehland) is a colloquial term often used to refer to Malaysia, symbolizing a sense of openness, possibility, and inclusivity. The (.xyz) extension adds a modern and global touch to our brand, signifying innovation, exploration, and creativity. Together, (bolehland.xyz) represents our commitment to providing a platform that embraces diversity, fosters creativity, and encourages exploration in a digital space.",
    },
    {
      question: "What is .xyz",
      answer:
        "(.xyz) is a generic top-level domain (gTLD) in the Domain Name System (DNS) of the internet. It was introduced in 2014 by XYZ.COM LLC as an alternative and versatile domain extension. The (.xyz) extension is widely recognized for its simplicity, flexibility, and affordability, making it a popular choice for individuals, businesses, and organizations worldwide. It offers a modern and memorable option for creating unique and innovative web addresses that stand out in a crowded online landscape. As such, we chose (.xyz) to align with our vision of being forward-thinking, globally accessible, and inclusive.",
    },
    // Add more FAQs as needed
  ];

  // State to track open/closed state of each FAQ
  const [openIndex, setOpenIndex] = useState(null);

  // Function to toggle open/close state of FAQ
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
  <section className="section-cta" id="faq">
    <div className="faq-section-container" >
      <div className="faq-section" >
      <span className="subheading"> </span>
     
      <span className="subheading">
        Frequently Asked Questions</span>
        {/* <h2>Frequently Asked Questions</h2> */}
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${openIndex === index ? "open" : ""}`}
              onClick={() => toggleFAQ(index)}
            >
              <button className="expand-button">{openIndex === index ? "-" : "+"}</button>
              <span>{faq.question}</span>
            </div>
            {openIndex === index && (
              <div className="faq-answer">{faq.answer}<br></br>{faq.image && <img className="faq-img" src={Activities} alt="FAQ Image" />}</div>
            )}
            
          </div>
       
  //          {openIndex === index && (
  //           <div className="faq-answer">
  //   {faq.image && <img src={Activities} alt="FAQ Image" />} {/* Render the image */}
    
  // </div>
  //         )}
        ))}
      </div>
    </div>
    </section>
  );
};

export default FAQSection;

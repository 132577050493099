import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import "./ScrollToTopButton.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Check if user has scrolled down enough to show the button
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) { // Adjust this value as needed
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <button
      className={`scroll-to-top ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
      title="Scroll to Top" // Add title attribute for the hint
    >
       <AiOutlineUp className="text-white larger-icon cursor-pointer" />
    </button>
  );
};

export default ScrollToTopButton;

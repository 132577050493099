import React from "react";

import Hero from "../components/Hero";
// import FeaturedIn from "../components/FeaturedIn";
import Insights from "../components/Insights";
import Clients  from "../components/Clients";
import Team from "../components/Team";
import Investment from "../components/Investment";
import ContactUs from "../components/ContactUs";
import FAQSection from "../components/FAQSection";

const HomePage = () => {


  return (

   <main> 

      <Hero />
      {/* <FeaturedIn /> */}
      <Insights />
      <Clients />
      <Team />
      <Investment />
      <ContactUs />
      <FAQSection />
    </main>

  );
};

export default HomePage;

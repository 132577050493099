import React from "react";
import { Link } from "react-scroll";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaFastForward } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { TbScale } from "react-icons/tb";

import "./Investment.css";

const Investment = () => {
  return (
    <section className="section-pricing" id="Investment">
      <div className="container">
        <span className="subheading">Investment</span>
        <h2 className="heading-secondary">
        Invest in your success with our flexible pricing plans
        </h2>
      </div>

      <div className="container grid grid--2-cols margin-bottom-md">
        <div className="pricing-plan pricing-plan--starter">
          <header className="plan-header">
            <p className="plan-name">Starter</p>
            <p className="plan-price">
              <span>RM</span>800
            </p>
            {/* <p className="plan-text">per month. That's just RM67!</p> */}
          </header>
          <ul className="list">
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Modern, mobile-responsive design</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Clear call-to-action elements</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>2 revisions for design and content</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Free hosting on Netlify or similar with SSL</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Monthly content updates (up to 6 times a year)</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Google indexing and Analytics configuration included</span>
            </li>
            {/* <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>SEO-friendly content (AI-assisted) based on client input.</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>2 revisions on design and content.</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Free hosting on Netlify or similar platforms.</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Monthly content update (minor edits and optimization).</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="close-outline"></ion-icon>
              <span className="feature-faded">
                Get access to latest recipes
              </span>
            </li> */}
          </ul>
          <div className="plan-sign-up">
            <Link
              className="btn btn--full"
              to="cta"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Contact Now
            </Link>
          </div>
        </div>

        <div className="pricing-plan pricing-plan--complete">
          <header className="plan-header">
            <p className="plan-name">Standard</p>
            <p className="plan-price">
              <span>RM</span>1500
            </p>
            {/* <p className="plan-text">per month. That's just RM125!</p> */}
          </header>
          <ul className="list">
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>
              Modern, mobile-responsive design
              </span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>
              Clear call-to-action elements
              </span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              3 revisions for design and content
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              Free hosting with your own domain name and SSL
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              Monthly content updates (up to 12 times a year)
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Google indexing and Analytics configuration included</span>
            </li>
            {/* <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Get access to latest recipes</span>
            </li> */}
          </ul>
          <div className="plan-sign-up">
            <Link
              className="btn btn--full"
              to="cta"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Contact Now
            </Link>
          </div>
        </div>
        

        
      </div>
      <div className="container grid grid--2-cols margin-bottom-md">
        <div className="pricing-plan pricing-plan--starter">
          <header className="plan-header">
            <p className="plan-name">Blog Site</p>
            <p className="plan-price">
              <span>RM</span>1800
            </p>
            {/* <p className="plan-text">per month. That's just RM67!</p> */}
          </header>
          <ul className="list">
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Basic theme design</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Paid hosting with your own domain name and SSL</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>3 revisions for design and content</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Google indexing and Analytics configuration included</span>
            </li>
            
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Option to monetize with Google AdSense later</span>
            </li>
            {/* <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>SEO-friendly content (AI-assisted) based on client input.</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>2 revisions on design and content.</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Free hosting on Netlify or similar platforms.</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Monthly content update (minor edits and optimization).</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="close-outline"></ion-icon>
              <span className="feature-faded">
                Get access to latest recipes
              </span>
            </li> */}
          </ul>
          <div className="plan-sign-up">
            <Link
              className="btn btn--full"
              to="cta"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Contact Now
            </Link>
          </div>
        </div>

        <div className="pricing-plan pricing-plan--complete">
          <header className="plan-header">
            <p className="plan-name">Community Forum</p>
            <p className="plan-price">
              <span>RM</span>2500
            </p>
            {/* <p className="plan-text">per month. That's just RM125!</p> */}
          </header>
          <ul className="list">
            <li className="list-item">
            
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>
              Custom plugins integration
              </span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>
              Social media login functionality
              </span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Google indexing and Analytics configuration included</span>
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              Paid hosting with your own domain name and SSL
            </li>
            <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Option to monetize with Google AdSense later</span>
            </li>
            {/* <li className="list-item">
              <ion-icon class="list-icon" name="checkmark-outline"></ion-icon>
              <span>Get access to latest recipes</span>
            </li> */}
          </ul>
          <div className="plan-sign-up">
            <Link
              className="btn btn--full"
              to="cta"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Contact Now
            </Link>
          </div>
        </div>
        

        
      </div>

      {/* <div className="container grid">
        <aside className="plan-details">
          *Paid Hosting: Customer to pay monthly to the cloud computing platform according to the server size chosen.
          All plans include the following:
        </aside>
      </div> */}

      <div className="container grid grid--4-cols">
        <div className="feature">
        <FaPeopleGroup className="text-white cursor-pointer larger-icon" />
          <p className="feature-title">Why Us</p>
          <p className="feature-text">
          Selecting us to build your landing page guarantees a custom solution crafted to your specific objectives. With our expertise and ongoing support, your page will captivate and convert visitors, ensuring lasting success in your digital marketing endeavors.
          </p>
        </div>
        <div className="feature">
        <FaFastForward className="text-white cursor-pointer larger-icon" />
          <p className="feature-title">Efficiency</p>
          <p className="feature-text">
          Save time and resources by entrusting the creation of your landing page to professionals who understand the intricacies of effective design, content, and user experience.
          </p>
        </div>
        <div className="feature">
        <MdOutlineDashboardCustomize className="text-white cursor-pointer larger-icon" />
          <p className="feature-title">Customization</p>
          <p className="feature-text">
          Enjoy a personalized approach where every aspect of your landing page, from design elements to call-to-action buttons, is tailored to reflect your brand identity and resonate with your audience
          </p>
        </div>
        <div className="feature">
        <TbScale className="text-white cursor-pointer larger-icon" />
          <p className="feature-title">Scalability</p>
          <p className="feature-text">
          As your business grows, your landing page can evolve alongside it. We offer scalable solutions that can accommodate your expanding needs and incorporate new features or functionalities as required.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Investment;

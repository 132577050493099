import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useEffect  } from 'react';
import emailjs from '@emailjs/browser';
import { useState } from 'react'

import "./ContactUs.css";

// //
// const initialState = {
//   name: '',
//   email: '',
//   message: '',
// }
// export const Contact = (props) => {
//   const [{ name, email, message }, setState] = useState(initialState)

//   const handleChange = (e) => {
//     const { name, value } = e.target
//     setState((prevState) => ({ ...prevState, [name]: value }))
//   }
//   const clearState = () => setState({ ...initialState })

//   const handleSubmit = (e) => {
//     e.preventDefault()
//     console.log(name, email, message)
//     //


//     const initialState = {
//       name: '',
//       email: '',
//       message: '',
//     }
//     export const Contact = (props) => {
//       const [{ name, email, message }, setState] = useState(initialState)
    
//       const handleChange = (e) => {
//         const { name, value } = e.target
//         setState((prevState) => ({ ...prevState, [name]: value }))
//       }
//       const clearState = () => setState({ ...initialState })
    
const ContactUs = () => {
 
  const form = useRef();
  
  
  


  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Check if the form has been submitted in the current session
    const submittedInSession = sessionStorage.getItem('formSubmitted');
    if (submittedInSession) {
      setSubmitted(true);
      disableForm();
    }
  }, []);


  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length <= 1000) {
      setMessage(value);
    }
  };
  

  const sendEmail = (e) => {

    e.preventDefault();
    if (!submitted) {
    emailjs.sendForm
    (
      process.env.REACT_APP_SERVICE, process.env.REACT_APP_TEMPLATE, e.target, process.env.REACT_APP_PUBLIC_KEY
    )
      .then
      (
          (result) => 
        {
            console.log(result.text);
            alert("Message sent! You will get an auto reply")
            // Mark the form as submitted in the current session
          sessionStorage.setItem('formSubmitted', true);
          setSubmitted(true);
          disableForm();
        }, 
          (error) => 
        {
            console.log(error.text);
            alert("Message not sent! Please try again.")
        }
      );
    } else {
      alert("You have already submitted the form in this session.");
    }
  };

  const disableForm = () => {
    if (form.current) {
      const elements = form.current.elements;
      for (let i = 0; i < elements.length; i++) {
        elements[i].disabled = true;
      }
    }
  };

  return (
    <section className="section-cta" id="ContactUs">
      <div className="container">
        <div className="cta">
          <div className="cta-text-box">
            <h2 className="heading-secondary">Get your Landing page Now!</h2>
            <p className="cta-text">
            Ready to elevate your online presence and grow your business? Contact us today to discuss your landing page needs, and let us help you achieve your digital marketing goals. With our expertise and personalized approach, success is just a click away!

Unlock the power of digital marketing and take your SME or individual expertise to new heights with BolehLand. Let's create a winning landing page together!
            </p>
            
            <form ref={form}  validate="true"  onSubmit={sendEmail} className="cta-form"
            >
              <input type="hidden" name="name" value="" />
              <div>
                <label htmlFor="name">Full Name</label>
                <input
                  id="name"
                  type="text"
                  placeholder="Your name"
                  name="name"
                  required
                />
              </div>

              <div>
                <label htmlFor="email">Email address</label>
                <input
                  id="email"
                  type="email"
                  placeholder="me@example.com"
                  name="email"
                  required
                />
              </div>

              <div>
                <label htmlFor="select-where">
                I am interested about landing page for?
                </label>
                <select id="message" name="message" required>
                  <option value="">Please choose one option:</option>
                  <option value="SME">SME</option>
                  <option value="My Home Business">My Home Business</option>
                  <option value="My Brand">My Brand</option>
                  <option value="My Expertise/Services">My Expertise/Services</option>
                  <option value="others">Others</option>
                </select>
              
              </div>
              <div>    <label htmlFor="message">Additional Notes</label>
                <textarea
                  id="message"
                  placeholder="Your extra notes to us"
                  name="message"
                  value={message}
                  onChange={handleChange}
                  required
                
                /><p>Characters remaining: {1000 - message.length}</p>
                </div>
             
              <button type="submit" value="Send" className='btn btn--form'>Submit</button>
              <br></br>{submitted && (
              <p className="form-submitted-message">You have already submitted the form in this session. Want to share more to us? No worry, email us manually at  <p> </p>
              <a
                className="all-recipes"
                href="mailto:bolehlandxyz@gmail.com?subject=LandingPage"
                target="_blank"
                rel="noreferrer noopener"
              >
                bolehlandxyz@gmail.com
              </a></p>
            )}
            </form>
          </div>
          <div
            className="cta-img-box"
            role="img"
            aria-label="bolehlandXYZContactUs"
          ></div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;


